import { Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NavController, ModalController } from '@ionic/angular';
import { GlobalAlignment } from 'src/app/interfaces/GlobalAlignment';
import { GgStorageService } from '../GgStorage/gg-storage.service';
import { GgEventService } from '../GgEvent/gg-event.service';
import { SignInPage } from 'src/app/pages/sign-in/sign-in.page';
import { FramePageData } from 'src/app/interfaces/FramePageData';
import { Language } from 'src/app/interfaces/Language';
import { Config } from 'src/app/app.const';
import { UtilityService } from '../utility/utility.service';
import { ApiService } from '../api/api.service';
import { GgNotifyService } from '../GgNotify/gg-notify.service';
import { Browser } from '@capacitor/browser';

@Injectable({
  providedIn: 'root',
})
export class GgPageService {
  private state: any = [];
  stateOfParentInfo: Array<any> = [];
  globalAlignment: GlobalAlignment;
  langCode: string = 'tr-TR';
  shortLangCode: string = 'tr';
  globalSettings: any;
  mobileSettings: any;
  mobileLanguages: Array<Language>;
  userData: any;

  constructor(
    private _router: Router,
    private _navCtrl: NavController,
    private _storage: GgStorageService,
    private _modalCtrl: ModalController,
    private _events: GgEventService,
    private _utility: UtilityService,
    private _apiService: ApiService,
    private _notify: GgNotifyService
  ) {
    this.pageInit();
  }

  returnForContent() {
    if (
      Config.homepageSelector === 'Home3' ||
      Config.homepageSelector === 'HomeBarut2'
    ) {
      return '90px';
    } else {
      return '10px';
    }
  }

  returnForFab() {
    if (
      Config.homepageSelector === 'Home3' ||
      Config.homepageSelector === 'HomeBarut2'
    ) {
      return '90px';
    } else {
      return '10px';
    }
  }
  //
  pageInit() {
    this._storage.get(Config.Keys.mobileSettings).then((response) => {
      this.mobileSettings = response;
    });

    this._events.subscribe(Config.Keys.mobileSettings, (response) => {
      this.mobileSettings = response;
    });

    this._storage.get(Config.Keys.globalSettings).then((response) => {
      this.globalSettings = response;
    });

    this._storage.get(Config.Keys.mobileLanguages).then((response) => {
      this.mobileLanguages = response;
    });

    this._events.subscribe(Config.Keys.mobileLanguages, (response) => {
      this.mobileLanguages = response;
    });

    this._storage.get(Config.Keys.languageCode).then((languageCode: string) => {
      this.langCode = languageCode;
      this.shortLangCode = this.getShortLanguageCode();
    });

    this._storage
      .get(Config.Keys.globalAlingment)
      .then((resp: GlobalAlignment) => {
        this.globalAlignment = resp;
        //bu code silinecek
        //this.globalAlignment.pageTitleAling = true;
      });
    this._events.subscribe(Config.Keys.globalAlingment, (globalAlign) => {
      this.globalAlignment = globalAlign;
    });
    this._storage.get(Config.Keys.userData).then((user) => {
      this.userData = user;
    });

    this._events.subscribe(Config.Keys.languageCode_refresh, (languageCode) => {
      this.langCode = languageCode;
      this.shortLangCode = this.getShortLanguageCode();
    });

    this._events.subscribe(Config.Keys.userData_refresh, (userData) => {
      this.userData = userData;
    });
  }

  isSignIn(redirectPage) {
    if (!this._utility.isNullOrUndefined(this.userData)) return true;
    else this.openSignInPageWithModal(redirectPage);
  }

  getShortLanguageCode() {
    return this.langCode.split('-')[0];
  }

  setData(id, data) {
    this.state[id] = data;
  }

  setDataForParentInfo(data) {
    let tempArray: Array<any> = [];
    tempArray.push(this.stateOfParentInfo, data);
    this.stateOfParentInfo = tempArray;
  }

  getDataForParentInfo() {
    return this.stateOfParentInfo[this.stateOfParentInfo.length - 1];
  }

  removeDataForParentInfo() {
    this.stateOfParentInfo.pop();
    if (this.stateOfParentInfo.length > 1) {
      this._events.publish(
        Config.Keys.isInfo,
        this.stateOfParentInfo[this.stateOfParentInfo.length - 1].isSettings
          .isInfo
      );
    } else {
      this._events.publish(Config.Keys.isInfo, false);
    }
  }

  removeAllDataForParentInfo() {
    this.stateOfParentInfo = [];
    this._events.publish(Config.Keys.isInfo, false);
  }

  getLegnhtForParentInfo() {
    return this.stateOfParentInfo.length;
  }

  getData(id) {
    return this.state[id];
  }

  go(page, pageTitle) {
    this.setData(Config.Keys.pageTitle, pageTitle);
    this._router.navigate([page]);
  }

  goBack() {
    if (this._router.url != '/parent-detail') {
      this.removeDataForParentInfo();
    }
    let urlSplit = this._router.url.split('#');
    if (
      urlSplit[urlSplit.length - 1].indexOf('/') == -1 &&
      urlSplit[urlSplit.length - 1] != undefined &&
      urlSplit[urlSplit.length - 1] != null &&
      urlSplit[urlSplit.length - 1].trim() != ''
    ) {
      this._navCtrl.back();
      setTimeout(() => {
        this.goBack();
      }, 150);
    } else {
      this._navCtrl.back();
      setTimeout(() => {
        if (this._router.url.substring(0, 14) != '/paid-services') {
          return new Promise((resolve, reject) => {
            this._storage.set(Config.Keys.shoppingCart, undefined);
            this._events.publish(Config.Keys.shoppingCart_refresh, []);
            resolve(undefined);
          });
        }

        if (
          this._router.url == null ||
          this._router.url == '/load' ||
          this._router.url == '/group-hotels' ||
          this._router.url == '/open-qr'
        ) {
          this._router.navigate(['/home']);
        }
      }, 500);
    }
  }

  goWithoutTitle(page) {
    if (page === 'open-door') {
      this._notify.show().then(() => {
        this._storage.get(Config.Keys.userData).then((userData) => {
          this._storage
            .get(Config.Keys.globalSettings)
            .then((globalSettings) => {
              this._notify.infiniteShow().then(() => {
                setTimeout(() => {
                  this._notify.infiniteClose();
                  this.checkFunc(page, globalSettings);
                }, 2000);
              });
            });
        });
      });
    } else {
      this._router.navigate([page]);
    }
  }

  checkFunc(page, globalSettings) {
    this._storage.get(Config.Keys.AssaRegisterEndpoint).then((endp) => {
      this.openDoorPageFunc(page, globalSettings);
    });
  }

  openDoorPageFunc(page, globalSettings) {
    this._storage.get(Config.Keys.IsOpenDoorActive).then((isActiveQ) => {
      if (isActiveQ !== null && isActiveQ !== undefined && isActiveQ) {
        this._notify.infiniteShow().then(() => {
          this._apiService
            .getGetway(globalSettings.opexURL, 'v1/AssaAbloyRegister', {
              Room: this.userData.roomNo,
              EndpointId: this.userData.hotelReservationId,
              lang: this.shortLangCode,
              key: false,
            })
            .subscribe(
              (response) => {
                if (response.StatusCode === 200) {
                  this._router.navigate([page]);
                } else if (
                  response.StatusCode === 404 ||
                  response.StatusCode === 400
                ) {
                  this._notify
                    .presentToast(null, response.Error, 'top', 'danger', 2000)
                    .then(() => {
                      if (this._router.url !== '/home') {
                        this._router.navigate(['/home']);
                      }
                      this._storage.remove(Config.Keys.userData).then(() => {
                        this._events.publish(
                          Config.Keys.userData_refresh,
                          null
                        );
                      });
                    });
                } else {
                  this._notify
                    .presentToast(
                      null,
                      response.Error.message || response.Error,
                      'top',
                      'danger',
                      2000
                    )
                    .then(() => { });
                }
                this._notify.infiniteClose();
              },
              () => {
                this._notify.infiniteClose();
              }
            );
        });
      } else {
        setTimeout(() => {
          this.openDoorPageFunc(page, globalSettings);
        }, 100);
      }
    });
  }

  openUrl(url: string) {
    Browser.open({
      url: url,
    });
    // window.open(url, '_blank');
  }

  openUrlInFrame(item: any) {
    var _framePageData: FramePageData = new FramePageData();
    _framePageData.url = item.languageContent[this.shortLangCode].webPageUrl;
    _framePageData.title = item.languageContent[this.shortLangCode].title;

    if (
      item.hotelReservationId != undefined &&
      item.hotelReservationId != null
    ) {
      _framePageData.url =
        _framePageData.url + '&customerID=' + item.hotelReservationId;
    }

    if (item.webPageUrl != undefined && item.webPageUrl != null) {
      _framePageData.url = item.webPageUrl;
    }

    this.setData(Config.Keys.framePageData, _framePageData);
    this.go('frame-page', _framePageData.title);
  }

  async openSignInPageWithModal(redirectPage: any) {
    const modal = await this._modalCtrl.create({
      component: SignInPage,
      cssClass: 'my-custom-class',
    });

    this._events.publish(Config.Keys.modalSignIn_added, modal);
    modal.onDidDismiss().then((result) => {
      if (result.data.userSign) {
        if (!this._utility.isNullOrUndefined(redirectPage)) {
          this.goWithoutTitle(redirectPage);
        }
      }
    });
    return await modal.present();
  }

  removeHTMLElement(text: string) {
    if (text == null) {
      return null;
    } else {
      let dtext = text.replace(/(<([^>]+)>)/gi, '');
      let tempText = this.replaceNbsps(dtext);
      return tempText;
    }
  }

  replaceNbsps(str) {
    let regex = new RegExp(/&(nbsp|amp|quot|lt|gt);/g);
    let result = str.replace(regex, ' ');
    return result;
  }
}
