import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { RemainingText } from 'src/app/interfaces/RemainingText';

@Injectable({
  providedIn: 'root'
})
export class UtilityService {

  constructor() { }

  isNullOrUndefined(value: any): boolean {
    if (value != undefined && value != null)
      return false;
    else
      return true;
  }

  isEmpty(value: string): boolean {
    if (!this.isNullOrUndefined(value)) {
      if (value.trim() != '')
        return false;
      else
        return true;
    }
    else
      return true;
  }

  isFull(value: any) {
    return !this.isNullOrUndefined(value);
  }

  isEmail(value: string): boolean {
    let regexp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    return regexp.test(value);
  }

  emailCryptoView(email: string) {
    if (!this.isEmpty(email)) {
      var atLater = email.split('@')[1];
      return `${email.substr(0, 3)}*****@${atLater.substr(0, 2)}*****.${atLater.split('.')[1]}`;
    }
    else
      return '';
  }

  isCryptoEmailView(email: string) {
    if (email.charAt(3) == '*' && email.split('@')[1].charAt(2) == '*')
      return true;
    else return false;
  }

  getCurrency(index) {
    //index;
    let currencys = ['₺', '₽', '€', '$', '£', 'Kč', 'PP'];
    return currencys[index];
  }

  getDiff(date) {

    date = moment(date, "DD/MM/YYYY hh:mm");
    var now = moment();
    var a = moment([date.year(), date.month() + 1, date.get('date'), date.hour(), date.minute()]);
    var b = moment([now.year(), now.month() + 1, now.get('date'), now.hour(), now.minute()]);


    var diffMonths = a.diff(b, 'month');
    var diffDay = a.diff(b, 'days');
    var diffHours = a.diff(b, 'hours');
    var diffMinutes = a.diff(b, 'minutes');
    var diffSize = a.diff(b);

    return { diffSize: diffSize, diffMonths: diffMonths, diffDay: diffDay, diffHours: diffHours, diffMinutes: diffMinutes };
  }

  getRemaining(date, remainingText: RemainingText) {
    let diff = this.getDiff(date);
    var remaining = { remainingTime: '', remainingColor: '' };

    if (diff.diffSize > 0) {
      var remainingTime = '';
      if (diff.diffDay > 0)
        remainingTime = `${diff.diffDay} ${remainingText.daysAfter} `;

      else if (diff.diffHours > 0)
        remainingTime = `${diff.diffHours} ${remainingText.hour}  ${diff.diffMinutes % 60} ${remainingText.minutesLeft}`;
      else
        remainingTime = `${diff.diffMinutes} ${remainingText.minutesLeft}`;

      if (diff.diffMinutes > 30)
        remaining.remainingColor = "primary";
      else
        remaining.remainingColor = "danger";

      remaining.remainingTime = remainingTime;
    }
    else {
      if (diff.diffMinutes < -10) {
        //kullanıcı reservasyon saatinden 10 dk sonra o reservasyonu değerlendirebilir
        remaining.remainingTime = `${remainingText.completed}`;
        remaining.remainingColor = 'success';
      }
      else {
        remaining.remainingTime = `${remainingText.start}`;
        remaining.remainingColor = 'dark';
      }
    }
    return remaining;
  }

  getReservationDateStatus(date) {
    let diff = this.getDiff(date);
    if (diff.diffSize > 0)
      return 0; else return 1;
  }

  //bu metot silinecek
  getReservationRate(date) {
    if (this.getReservationDateStatus(date) == 0)
      return undefined;
    else
      return Math.floor(Math.random() * 6) + 1
  }


  getRestrictedText(text: string, length: number) {
    if (text.length > length)
      return text.substring(0, length) + " ...";
    else
      return text;
  }

}