import { Hotels } from './app.hotels.config';

export var Config = {
  const: 'gonca-mobile-app-v4',
  homepageSelector: null,
  Api: {
    //url: 'https://gms.goncagrubu.com/api',//OLD
    url: 'https://app.hhoms.com/api', //LIVE
    ycp: 'http://176.53.22.133:84/saglik/api',
    apigetwayToken: 'd78852af-513d-4d31-8711-e1c269def9ee',
    apiId: 'Gonca',
    apiKey: 'A79d33Cdeds380e14938xT',

    AppID: {
      appID: '25bf5f8b-acdf-4d36-8406-13f7375bc416',
    },
    version: 'v1',

    // OneSignal Google Project Number
    OwnerGroupID: Hotels.ACTIVE_HOTEL.ownerGroupId,
    OneSignalGoogleProjectNumber: '244400964016',
    homepageToolbar: false,
    HotelOpenWithQR: false,
    OpenGroupPage: false,
    AssaAbloy: false,
    IsPersonel: false,

    Qr: 'v1/Qr',
    Baskets: 'v1/Baskets',
    StaticPageMobileLanguages: 'v1/StaticPageMobileLanguages',

    getway: 'v1/Getway',
    SMS: 'v1/sms',
    MobexHomePages: 'v1/MobexHomePages',
    MobexHomePagesWithoutUserForPersonel: 'v1/MobexHomePages/true',
    MobileRegisters: 'v1/MobileRegisters',
    MobileUserSettings: 'v1/MobileUserSettings',
    MobileContacts: 'v1/MobileContacts',
    MobileSettings: 'v1/MobileSettings',
    MobileStatistics: 'v1/MobileStatistics',
    MobileMenus: 'v1/MobileMenus',
    MobileImageGalleries: 'v1/MobileImageGalleries', //gms

    MobileGuestContacts: 'v1/MobileGuestContacts', //gms
    MobileProfilePages: 'v1/MobileProfilePages', //gms
    MobileNotificationLists: 'v1/MobileNotificationLists', //gms
    MobileNotificationList_UnreadCount:
      'v1/MobileNotificationLists/unReadCount', //gms

    Account_Login: 'v1/Account/Login', //gms
    Account_Login_User: 'v1/Account/LoginWithUser', //gms
    Account_Login_Email: 'v1/Account/loginWithEmail', //gms
    Account_ResetMailAndPassword: 'v1/Account/resetmailandpassword', //gms
    Account_ForgotPassword: 'v1/Account/ForgotPassword', //gms
    Account_ForgotPasswordMail: 'v1/Account/emailforgotpassword', //gms
    Account_Cancel: 'v1/Account/accountcancel', //gms

    MobexInfos: 'v1/MobexInfos',
    MobexInfos_Detail: 'v1/MobexInfos/detail',
    MobexCampaigns: 'v1/MobexCampaigns',
    MobexCampaigns_detail: 'v1/MobexCampaigns/detail',
    MobexCampaigns_thirdParty: 'v1/MobexCampaigns/thirdParty', //gms
    MobexNotices: 'v1/MobexNotices',
    MobexNotices_detail: 'v1/MobexNotices/detail',
    MobexFreeServices: 'v1/MobexFreeServices',
    MobexFreeServicesDetail: 'v1/MobexFreeServices/detail', //???
    MobexPaidServices: 'v1/MobexPaidServices',
    MobexPaidServices_detail: 'v1/MobexPaidServices/detail',
    MobexActivitys_detail: 'v1/MobexActivitys/detail',
    MobexActivitys: 'v1/MobexActivitys',

    MobexOrders: 'v1/MobexOrders', ///gms
    MobexOrders_group: 'v1/MobexOrders/orderGroup', //gms
    MobexOrders_PaidService: 'v1/MobexOrders/paidService', //gms
    MobexOrders_PaidService_Print: 'v1/MobexOrders/paidService/print',
    MobexOrder_UserPoint: 'v1/MobexOrders/mobexOrderPoints', //gms
    MobexOrder_Last: 'v1/MobexOrders/last', //gms
    MobexOrders_FreeService: 'v1/MobexOrders/freeService', //gms
    MobexOrders_personalCare: 'v1/MobexOrders/personalCare', //gms
    MobexOrders_Activitys: 'v1/MobexOrders/activity', //gms
    MobexOrders_Survey: 'v1/MobexOrders/survey', //gms

    MobexSurveys: 'v1/MobexSurveys', //gms

    MobexAlaCartes: 'v1/MobexAlaCartes',
    MobexAlaCartes_alacarteTables: 'MobexAlaCartes/alacarteTables', //???
    MobexAlaCartes_tableStatusBusy: 'MobexAlaCartes/tableStatusBusy', //???
    MobexAlaCartes_tableStatusSuitable: 'MobexAlaCartes/tableStatusSuitable', //???
    MobexAlaCartes_checkChild: 'MobexAlaCartes/checkChild', //???
    MobexAlaCartes_reservation: 'MobexAlaCartes/reservation', //???
    MobexAlaCartes_PavilionReservation: 'MobexAlaCartes/PavilionReservation', //???
    MobexAlaCartes_checkTableStatus: 'MobexAlaCartes/checkTableStatus', //???
    MobexAlaCartes_guestReservations: 'MobexAlaCartes/guestReservations', //???

    Owners: 'v1/Owners',
    Owners_Adress: 'v1/Owners/address', //gms
    Owners_Adress_Detail: 'v1/Owners/addressdetail', //gms

    MobexCancelStatus: 'v1/MobexCancelStatus', //gms
    MobexCustomers: 'v1/MobexCustomers', //gms
    MobexCustomers_addImage: 'v1/MobexCustomers/addImage', //gms
    MobexCustomers_checkEmail: 'v1/MobexCustomers/checkEmail', //gms

    PersonalCareProductCategories: 'v1/PersonalCareProductCategories', //gms

    Countries: 'v1/Countries', //gms
    Cities: 'v1/Cities', //gms
    BirthDays: 'v1/MobexCustomers/birthday',

    Weather: 'v1/Weather',
  },

  //Version: 'v6.2.1',
  Version: 'vAll',
  PageNames: {
    HomePage: 'HomePage',
    MobexInfo: 'MobexInfo',
    MobexDictionary: 'MobexDictionary',
    MobexInfoDetail: 'MobexInfoDetail',
    MobexActivity: 'MobexActivity',
    MobexFreeService: 'MobexFreeService',
    MobexPaidService: 'MobexPaidService',
    WebPage: 'WebPage',
    FramePage: 'FramePage',
    MobexAlacarte: 'MobexAlacarte',
    MobexBarutAlacarte: 'MobexBarutAlacarte', //* TODO
    MobexPavilion: 'MobexPavilion',
    MobexBarutPavilion: 'MobexBarutPavilion', //*
    MobexSettingsContact: 'MobexSettingsContact',
    MobilProfile: 'MobilProfile',
    MobilRegister: 'MobilRegister',
    MobexCampaing: 'MobexCampaing',
    MobexNotices: 'MobexNotices',
    MobexSpecial: 'MobexSpecial',
    MobexCompCampaing: 'MobexCompCampaing',
    MobexCampaingDetail: 'MobexCampaingDetail',
    MobexCompCampaingDetail: 'MobexCompCampaingDetail',
    MobexOwner: 'MobexOwner',
    MobexInfoSSS: 'MobexInfoSSS',
    PastOrderAlertLang: 'PastOrderAlertLang',
    OpenQRPage: 'OpenQRPage',
    RegisterPage: 'RegisterPage',
    MobexProducts: 'MobexProducts',
    MobileLanguages: 'MobileLanguages',
    MobilVersion: 'MobilVersion',
    LoadPage: 'LoadPage',
    HotelFindPage: 'HotelFindPage',
    MobexFoodService: 'MobexFoodService',
    MobexPrintFoodService: 'MobexPrintFoodService',
    MobilQrReader: 'MobilQrReader',
    MobexCampaingPage: 'MobexCampaingPage',
    MobexPaidServicePage: 'MobexPaidServicePage',
    SurvexWeb: 'SurvexWeb',
    Survex: 'Survex',
    HotSpot: 'HotSpot',
    OpenDoor: 'OpenDoor',
    MobexInfoSlider: 'MobexInfoSlider',
    CustomerBirthday: 'CustomerBirthday',
    CampaignDetail: 'CampaignDetail',
    AnnouncementDetail: 'AnnouncementDetail',
    FreeServiceDetail: 'FreeServiceDetail',
  },
  Defaults: {
    NotificationStatus: true,
    AppPoint: 0,
    LongLanguageCode: 'tr-TR',
    ShortLanguageCode: 'tr',
  },
  Keys: {
    ownerID: 'ownerID',
    ParentID: 'ParentID',
    developer: 'developer',
    webmobil: 'webmobile',
    item: 'item',
    pageLanguageContent: 'pageLanguageContent',
    parametrId: 'parametrId',
    userData: 'userData',
    oldUserHotelReservationIdForAssa: 'oldUserHotelReservationIdForAssa',
    IsOpenDoorActive: 'isOpenDoorActiveForRouting',
    framePageData: 'framePageData',
    register: 'register',
    registerToken: 'registerToken',
    pageTitle: 'pageTitle',
    notificationStatus: 'notificationStatus',
    languageCode: 'languageCode',
    mobileSettings: 'mobileSettings',
    fontSettings: 'fontSettings',
    globalSettings: 'globalSettings',
    mobileLanguages: 'mobileLanguages',
    globalAlingment: 'globalAlingment',
    reservationType: 'reservationType',
    reservationInfo: 'reservationInfo',
    modalSignIn_dismiss: 'modalSignIn:dismiss',
    shoppingCart: 'shoppingCart',
    printerShoppingCart: 'printerShoppingCart',
    printerShoppingCart_refresh: 'printerShoppingCart:refresh',
    shoppingCart_refresh: 'shoppingCart:refresh',
    spaShoppingCart: 'shoppingCart',
    spaShoppingCart_refresh: 'shoppingCart:refresh',
    languageCode_refresh: 'languageCode:refresh',
    modalSignIn_added: 'modalSignIn:added',
    userData_refresh: 'userData:refresh',
    hideServiceDetail: 'hideServiceDetail',
    Activitys: 'Activitys',
    Alacartes: 'Alacartes',
    Campaigns: 'Campaign',
    FreeServices: 'FreeServices',
    PaidServices: 'PaidServices',
    campaign: 'campaign',
    selectedItem: 'selectedItem',
    ActivityDetailPage: 'ActivityDetailPage',
    ActivityItem: 'ActivityItem',
    ActiveHotel: 'ActiveHotel',
    ActiveHotel_Refresh: 'AcitiveHotelRefresh',
    ActiveHotelSelected: false,
    ActiveHotelSelectAfter: 'ActiveHotelAfterSelect',
    PersonalCare: 'PersonalCare',
    SednaSettings: 'SednaSettings',
    pageItem: 'pageItem',
    QRType: 'QRType',
    isInfo: 'isInfo',
    randomizedCampaigns: 'RandomizedCampaignList',
    AssaRegisterEndpoint: 'assa-register-endpoint',
    UrlHotel: 'url-sended-hotel',
    SavedWithToken: 'saved-hotel-is-token',
  },
  OrderTable: {
    Activitys: 1,
    Alacartes: 2,
    Campaign: 3,
    FreeServices: 4,
    PaidServices: 5,
    Surveys: 7,
    PersonalCare: 7,
  },
};
