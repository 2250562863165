import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { Config } from './app.const';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => {
      if (Config.Api.IsPersonel) {
        return import('./pages/group-login/group-login.module').then(
          (m) => m.GroupLoginPageModule
        );
      } else {
        return import('./pages/group-hotels/group-hotels.module').then(
          (m) => m.GroupHotelsPageModule
        );
      }
    },
  },
  {
    path: 'home',
    loadChildren: () => {
      if (Config.homepageSelector != null) {
        if (Config.homepageSelector == 'Home1') {
          return import('./pages/home/home.module').then(
            (m) => m.HomePageModule
          );
        } else if (Config.homepageSelector == 'HomeBarut') {
          return import('./pages/home-barut/home-barut.module').then(
            (m) => m.HomeBarutPageModule
          );
        } else if (Config.homepageSelector == 'HomeBarut2') {
          return import(
            './pages/home-barut-second/home-barut-second.module'
          ).then((m) => m.BarutHomeSecondPageModule);
        } else if (Config.homepageSelector == 'Home2') {
          return import('./pages/home2/home2.module').then(
            (m) => m.Home2PageModule
          );
        } else if (Config.homepageSelector == 'Home3') {
          return import('./pages/home3/home3.module').then(
            (m) => m.Home3PageModule
          );
        } else if (Config.homepageSelector == 'HomeIcon') {
          return import('./pages/home-icons/home-icons.module').then(
            (m) => m.HomeIconsPageModule
          );
        } else if (Config.homepageSelector == 'BarutAcademy') {
          return import(
            './pages/home-barut-academy/home-barut-academy.module'
          ).then((m) => m.BarutAcademyPageModule);
        } else if (Config.homepageSelector == 'CullinanHome') {
          return import('./pages/home-cullinan/home-cullinan.module').then(
            (m) => m.CullinanHomePageModule
          );
        } else if (Config.homepageSelector == 'TravelHome') {
          return import('./pages/home-travel/home-travel-routing.module').then(
            (m) => m.TravelHomePageRoutingModule
          );
        }
      } else {
        setTimeout(() => {
          if (Config.homepageSelector == 'Home1') {
            return import('./pages/home/home.module').then(
              (m) => m.HomePageModule
            );
          } else if (Config.homepageSelector == 'HomeBarut') {
            return import('./pages/home-barut/home-barut.module').then(
              (m) => m.HomeBarutPageModule
            );
          } else if (Config.homepageSelector == 'HomeBarut2') {
            return import(
              './pages/home-barut-second/home-barut-second.module'
            ).then((m) => m.BarutHomeSecondPageModule);
          } else if (Config.homepageSelector == 'Home2') {
            return import('./pages/home2/home2.module').then(
              (m) => m.Home2PageModule
            );
          } else if (Config.homepageSelector == 'Home3') {
            return import('./pages/home3/home3.module').then(
              (m) => m.Home3PageModule
            );
          } else if (Config.homepageSelector == 'HomeIcon') {
            return import('./pages/home-icons/home-icons.module').then(
              (m) => m.HomeIconsPageModule
            );
          } else if (Config.homepageSelector == 'BarutAcademy') {
            return import(
              './pages/home-barut-academy/home-barut-academy.module'
            ).then((m) => m.BarutAcademyPageModule);
          } else if (Config.homepageSelector == 'CullinanHome') {
            return import('./pages/home-cullinan/home-cullinan.module').then(
              (m) => m.CullinanHomePageModule
            );
          } else if (Config.homepageSelector == 'TravelHome') {
            return import(
              './pages/home-travel/home-travel-routing.module'
            ).then((m) => m.TravelHomePageRoutingModule);
          } else {
            return import('./pages/home/home.module').then(
              (m) => m.HomePageModule
            );
          }
        }, 1500);
      }
    },
  },
  {
    path: 'mobex-info/:id',
    loadChildren: () =>
      import('./pages/mobex-info/mobex-info.module').then(
        (m) => m.MobexInfoPageModule
      ),
  },
  {
    path: 'mobex-free-services/:id',
    loadChildren: () =>
      import('./pages/mobex-free-services/mobex-free-services.module').then(
        (m) => m.MobexFreeServicesPageModule
      ),
  },
  {
    path: 'mobex-free-services-detail',
    loadChildren: () =>
      import(
        './pages/mobex-free-services-detail/mobex-free-services-detail.module'
      ).then((m) => m.MobexFreeServicesDetailPageModule),
  },
  {
    path: 'mobex-info-detail',
    loadChildren: () =>
      import('./pages/mobex-info-detail/mobex-info-detail.module').then(
        (m) => m.MobexInfoDetailPageModule
      ),
  },
  {
    path: 'info-detail',
    loadChildren: () =>
      import('./pages/info-detail/info-detail.module').then(
        (m) => m.InfoDetailPageModule
      ),
  },
  {
    path: 'frame-page',
    loadChildren: () =>
      import('./pages/frame-page/frame-page.module').then(
        (m) => m.FramePagePageModule
      ),
  },
  {
    path: 'activity/:id/:date',
    loadChildren: () =>
      import('./pages/activity/activity.module').then(
        (m) => m.ActivityPageModule
      ),
  },
  {
    path: 'activity-detail',
    loadChildren: () =>
      import('./pages/activity-detail/activity-detail.module').then(
        (m) => m.ActivityDetailPageModule
      ),
  },
  {
    path: 'alacarte-pavilion',
    loadChildren: () =>
      import('./pages/alacarte-pavilion/alacarte-pavilion.module').then(
        (m) => m.AlacartePavilionPageModule
      ),
  },
  {
    path: 'alacarte-pavilion-detail',
    loadChildren: () =>
      import(
        './pages/alacarte-pavilion-detail/alacarte-pavilion-detail.module'
      ).then((m) => m.AlacartePavilionDetailPageModule),
  },
  {
    path: 'sign-in',
    loadChildren: () =>
      import('./pages/sign-in/sign-in.module').then((m) => m.SignInPageModule),
  },
  {
    path: 'settings',
    loadChildren: () =>
      import('./pages/settings/settings.module').then(
        (m) => m.SettingsPageModule
      ),
  },
  {
    path: 'profile',
    loadChildren: () =>
      import('./pages/profile/profile.module').then((m) => m.ProfilePageModule),
  },
  {
    path: 'alacarte-pavilion-reservation',
    loadChildren: () =>
      import(
        './pages/alacarte-pavilion-reservation/alacarte-pavilion-reservation.module'
      ).then((m) => m.AlacartePavilionReservationPageModule),
  },
  {
    path: 'contact',
    loadChildren: () =>
      import('./pages/contact/contact.module').then((m) => m.ContactPageModule),
  },
  {
    path: 'reservations',
    loadChildren: () =>
      import('./pages/reservations/reservations.module').then(
        (m) => m.ReservationsPageModule
      ),
  },
  {
    path: 'dictionary',
    loadChildren: () =>
      import('./pages/dictionary/dictionary.module').then(
        (m) => m.DictionaryPageModule
      ),
  },
  {
    path: 'paid-services/:id',
    loadChildren: () =>
      import('./pages/paid-services/paid-services.module').then(
        (m) => m.PaidServicesPageModule
      ),
  },
  {
    path: 'paid-service-detail',
    loadChildren: () =>
      import('./pages/paid-service-detail/paid-service-detail.module').then(
        (m) => m.PaidServiceDetailPageModule
      ),
  },
  {
    path: 'campaigns',
    loadChildren: () =>
      import('./pages/campaigns/campaigns.module').then(
        (m) => m.CampaignsPageModule
      ),
  },
  {
    path: 'campaign-detail',
    loadChildren: () =>
      import('./pages/campaign-detail/campaign-detail.module').then(
        (m) => m.CampaignDetailPageModule
      ),
  },
  {
    path: 'basket',
    loadChildren: () =>
      import('./pages/basket/basket.module').then((m) => m.BasketPageModule),
  },
  {
    path: 'basket-print',
    loadChildren: () =>
      import('./pages/basket-print/basket-print.module').then(
        (m) => m.BasketPrintPageModule
      ),
  },
  {
    path: 'reservation-completed',
    loadChildren: () =>
      import('./pages/reservation-completed/reservation-completed.module').then(
        (m) => m.ReservationCompletedPageModule
      ),
  },
  {
    path: 'my-activities',
    loadChildren: () =>
      import('./pages/my-activities/my-activities.module').then(
        (m) => m.MyActivitiesPageModule
      ),
  },
  {
    path: 'my-paid-services',
    loadChildren: () =>
      import('./pages/my-paid-services/my-paid-services.module').then(
        (m) => m.MyPaidServicesPageModule
      ),
  },
  {
    path: 'start-up',
    loadChildren: () =>
      import('./pages/start-up/start-up.module').then(
        (m) => m.StartUpPageModule
      ),
  },
  {
    path: 'qr',
    loadChildren: () =>
      import('./pages/qr/qr.module').then((m) => m.QrPageModule),
  },
  {
    path: 'my-free-services',
    loadChildren: () =>
      import('./pages/my-free-services/my-free-services.module').then(
        (m) => m.MyFreeServicesPageModule
      ),
  },
  {
    path: 'my-campaigns',
    loadChildren: () =>
      import('./pages/my-campaigns/my-campaigns.module').then(
        (m) => m.MyCampaignsPageModule
      ),
  },
  {
    path: 'announcements',
    loadChildren: () =>
      import('./pages/announcements/announcements.module').then(
        (m) => m.AnnouncementsPageModule
      ),
  },
  {
    path: 'connection-lost',
    loadChildren: () =>
      import('./pages/connection-lost/connection-lost.module').then(
        (m) => m.ConnectionLostPageModule
      ),
  },
  {
    path: 'mobex-special',
    loadChildren: () =>
      import('./pages/mobex-special/mobex-special.module').then(
        (m) => m.MobexSpecialPageModule
      ),
  },
  {
    path: 'announcement-detail',
    loadChildren: () =>
      import('./pages/announcement-detail/announcement-detail.module').then(
        (m) => m.AnnouncementDetailPageModule
      ),
  },
  {
    path: 'campaign-special',
    loadChildren: () =>
      import('./pages/campaign-special/campaign-special.module').then(
        (m) => m.CampaignSpecialPageModule
      ),
  },
  {
    path: 'group-hotels',
    loadChildren: () =>
      import('./pages/group-hotels/group-hotels.module').then(
        (m) => m.GroupHotelsPageModule
      ),
  },
  {
    path: 'faq',
    loadChildren: () =>
      import('./pages/faq/faq.module').then((m) => m.FAQPageModule),
  },
  {
    path: 'open-qr',
    loadChildren: () =>
      import('./pages/open-qr/open-qr.module').then((m) => m.OpenQrPageModule),
  },
  {
    path: 'register',
    loadChildren: () =>
      import('./pages/register/register.module').then(
        (m) => m.RegisterPageModule
      ),
  },
  {
    path: 'spa',
    loadChildren: () =>
      import('./pages/spa/spa.module').then((m) => m.SpaPageModule),
  },
  {
    path: 'spa-category',
    loadChildren: () =>
      import('./pages/spa-category/spa-category.module').then(
        (m) => m.SpaCategoryPageModule
      ),
  },
  {
    path: 'spa-basket',
    loadChildren: () =>
      import('./pages/spa-basket/spa-basket.module').then(
        (m) => m.SpaBasketPageModule
      ),
  },
  {
    path: 'my-product',
    loadChildren: () =>
      import('./pages/my-product/my-product.module').then(
        (m) => m.MyProductPageModule
      ),
  },
  {
    path: 'group-filter',
    loadChildren: () =>
      import('./pages/group-filter/group-filter.module').then(
        (m) => m.GroupFilterPageModule
      ),
  },
  {
    path: 'callendar-modal',
    loadChildren: () =>
      import('./pages/callendar-modal/callendar-modal.module').then(
        (m) => m.CallendarModalPageModule
      ),
  },
  {
    path: 'callendar2-modal',
    loadChildren: () =>
      import('./pages/callendar2-modal/callendar2-modal.module').then(
        (m) => m.Callendar2ModalPageModule
      ),
  },
  {
    path: 'paid-services-second/:id',
    loadChildren: () =>
      import('./pages/paid-services-second/paid-services-second.module').then(
        (m) => m.PaidServicesSecondPageModule
      ),
  },
  {
    path: 'paid-services-print/:id',
    loadChildren: () =>
      import('./pages/paid-services-print/paid-services-print.module').then(
        (m) => m.PaidServicesPrintPageModule
      ),
  },
  {
    path: 'qr-scanner',
    loadChildren: () =>
      import('./pages/qr-scanner/qr-scanner.module').then(
        (m) => m.QrScannerPageModule
      ),
  },
  {
    path: 'parent-detail',
    loadChildren: () =>
      import('./pages/parent-detail/parent-detail.module').then(
        (m) => m.ParentDetailPageModule
      ),
  },
  {
    path: 'random-campaign',
    loadChildren: () =>
      import('./pages/random-campaign/random-campaign.module').then(
        (m) => m.RandomCampaignPageModule
      ),
  },
  {
    path: 'user-options',
    loadChildren: () =>
      import('./pages/user-options/user-options.module').then(
        (m) => m.UserOptionsPageModule
      ),
  },
  {
    path: 'user-lasts',
    loadChildren: () =>
      import('./pages/user-lasts/user-lasts.module').then(
        (m) => m.UserLastsPageModule
      ),
  },
  {
    path: 'open-door',
    loadChildren: () =>
      import('./pages/open-door/open-door.module').then(
        (m) => m.OpenDoorPageModule
      ),
  },
  {
    path: 'home-icons',
    loadChildren: () =>
      import('./pages/home-icons/home-icons.module').then(
        (m) => m.HomeIconsPageModule
      ),
  },
  {
    path: 'hour-modal',
    loadChildren: () =>
      import('./pages/hour-modal/hour-modal.module').then(
        (m) => m.HourModalPageModule
      ),
  },
  {
    path: 'anket-home',
    loadChildren: () =>
      import('./pages/anket-home/anket-home.module').then(
        (m) => m.AnketHomePageModule
      ),
  },
  {
    path: 'anket-bad-answer',
    loadChildren: () =>
      import('./pages/anket-bad-answer/anket-bad-answer.module').then(
        (m) => m.AnketBadAnswerPageModule
      ),
  },
  {
    path: 'confirm-sms',
    loadChildren: () =>
      import('./pages/confirm-sms/confirm-sms.module').then(
        (m) => m.ConfirmSmsPageModule
      ),
  },
  {
    path: 'mobex-info-slider/:id',
    loadChildren: () =>
      import('./pages/mobex-info-slider/mobex-info-slider.module').then(
        (m) => m.MobexInfoSliderPageModule
      ),
  },
  {
    path: 'barut-academy',
    loadChildren: () =>
      import('./pages/home-barut-academy/home-barut-academy.module').then(
        (m) => m.BarutAcademyPageModule
      ),
  },
  {
    path: 'birth-days',
    loadChildren: () =>
      import('./pages/birth-days/birth-days.module').then(
        (m) => m.BirthDaysPageModule
      ),
  },
  {
    path: 'group-login',
    loadChildren: () =>
      import('./pages/group-login/group-login.module').then(
        (m) => m.GroupLoginPageModule
      ),
  },
  {
    path: 'change-mail',
    loadChildren: () =>
      import('./pages/change-mail/change-mail.module').then(
        (m) => m.ChangeMailPageModule
      ),
  },
  {
    path: 'profile-personel',
    loadChildren: () =>
      import('./pages/profile-personel/profile-personel.module').then(
        (m) => m.ProfilePersonelPageModule
      ),
  },
  {
    path: 'cullinan-home',
    loadChildren: () =>
      import('./pages/home-cullinan/home-cullinan.module').then(
        (m) => m.CullinanHomePageModule
      ),
  },
  {
    path: 'travel-home',
    loadChildren: () =>
      import('./pages/home-travel/home-travel.module').then(
        (m) => m.TravelHomePageModule
      ),
  },
  {
    path: 'video-content',
    loadChildren: () =>
      import('./pages/video-content/video-content.module').then(
        (m) => m.VideoContentPageModule
      ),
  },
  {
    path: 'video-modal',
    loadChildren: () =>
      import('./pages/video-modal/video-modal.module').then(
        (m) => m.VideoModalPageModule
      ),
  },
  {
    path: 'pdf-modal',
    loadChildren: () =>
      import('./pages/pdf-modal/pdf-modal.module').then(
        (m) => m.PdfModalPageModule
      ),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
