import { Component, OnInit } from '@angular/core';

import { MenuController, ModalController, Platform } from '@ionic/angular';
import { ApiService } from './services/api/api.service';
import { GgPageService } from './services/ggPage/gg-page.service';
import { GgNotifyService } from './services/GgNotify/gg-notify.service';
import { GgEventService } from './services/GgEvent/gg-event.service';
import { Router, Event, NavigationStart, NavigationEnd } from '@angular/router';
import { GgStorageService } from './services/GgStorage/gg-storage.service';
import { GetMenuListService } from './services/get-menu-list/get-menu-list.service';
import { GetGlobalSettingsService } from './services/get-global-settings/get-global-settings.service';
import { OpenPageService } from './services/open-page/open-page.service';
import { MenuList } from './interfaces/MenuList';
import { Config } from './app.const';
import { CompCampaignService } from './services/CompCampaign/comp-campaign.service';
import MobileKeys from './interfaces/AssaMobileKeys';
import { GgNetworkService } from './services/GgNetwork/gg-network.service';
import { UtilityService } from './services/utility/utility.service';
import { SurveyService } from './services/survey/survey.service';
import { Hotels } from './app.hotels.config';
import { Location } from '@angular/common';
import { Device } from '@awesome-cordova-plugins/device/ngx';
import { RandomCampaignPage } from './pages/random-campaign/random-campaign.page';
import * as moment from 'moment';
import { ActivityService } from './services/activity/activity.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  uniLang: string = Config.Defaults.LongLanguageCode;
  shortUniLang: string = Config.Defaults.ShortLanguageCode;
  globalWhiteLogo: string = null;
  globalStyles: any = '<style></style>';
  langItem: any;
  versionLang: any;
  mobileLang: any;
  langItemID: number = 1;
  userData: Object | any = {};
  MenuList: MenuList = new MenuList();
  globalModal: Array<any> = [];
  leftBackground: string = null;
  versionNumber: string = Config.Version;
  firstLaunchApp: boolean = true;
  refreshLanguageStatus: boolean = false;
  isPageNotification: boolean = false;
  reSummonInitializeApp: boolean = true;
  unReadNotificationCount: number = 0;
  isOpenClickedTNotification: boolean = false;
  page: GgPageService;
  titleFont: string = null;

  isInfo: boolean = false;
  checkedVisibleStatus: boolean = false;
  infoItem: any;
  urlCheckinfForParentInfo: boolean = false;
  withoutTabPages: Array<string> = [
    'load',
    'sign-in',
    'qr',
    'start-up',
    'connection-lost',
    'open-qr',
    'qr-scanner',
    'group-filter',
    'group-hotels',
    'group-login',
    'settings',
    'profile',
  ];
  withoutInfoPages: Array<string> = [
    'load',
    'home',
    'mobex-free-services-detail',
    'ycp',
    'mobex-info-detail',
    'frame-page',
    'activity',
    'activity-detail',
    'alacarte-pavilion-detail',
    'sign-in',
    'settings',
    'profile',
    'contact',
    'reservations',
    'dictionary',
    'paid-service-detail',
    'campaign-detail',
    'basket',
    'reservation-completed',
    'my-activities',
    'my-paid-services',
    'start-up',
    'qr',
    'my-free-services',
    'my-campaigns',
    'announcements',
    'connection-lost',
    'announcement-detail',
    'group-hotels',
    'faq',
    'open-qr',
    'register',
    'spa',
    'spa-category',
    'spa-basket',
    'my-product',
    'group-filter',
    'callendar-modal',
    'callendar2-modal',
    'qr-scanner',
    'parent-detail',
  ];

  testbool: boolean = false;
  menuOpened: boolean = false;
  tabVisible: boolean = false;
  openedRandomCamp: boolean = false;
  whatsappNumber: string = null;
  liveSupportVisible: boolean = false;
  liveSupportStyle: string = '10px !important';
  additionalDataCustom: Object = {
    id: '66',
    subtitle:
      'Değerli Misafirlerimiz,Öneri ve değerlendirmeleriniz sizlere daha iyi bir hizmet sunabilmemiz için önemlidir. Bize birkaç daki',
    title: 'Şok Anket',
    type: 'Notice',
  };
  doneInitalizing: boolean = false;
  downloadOurAppButton: boolean = false;
  downloadButtonVisible: boolean = false;
  selectedValueForDownloadButton: string | null = null;
  globSettings: any;
  globLangContent: any;
  routingPageName: string | null = null;
  routingPageID: string | null = null;
  isLeftMenuActive: boolean = false;
  toolbarColor: string = '#222428';
  isDesktop: boolean = false;

  constructor(
    public _platform: Platform,
    private ggPage: GgPageService,
    public notify: GgNotifyService,
    private _events: GgEventService,
    private _globalSettings: GetGlobalSettingsService,
    private router: Router,
    private _storage: GgStorageService,
    private _getMenu: GetMenuListService,
    public api: ApiService,
    private _openPage: OpenPageService,
    private _modalCtrl: ModalController,
    private _campaign: CompCampaignService,
    private _menu: MenuController,
    private _utility: UtilityService,
    private _survey: SurveyService,
    private _location: Location,
    private _device: Device,
    private _activity: ActivityService
  ) {
    let path = this._location.path(true);
    let queryParams = new URLSearchParams(path.replace(/^([^?]*)?\??/, ''));
    let qPar = queryParams.get('q');

    let hotel = queryParams.get('Hotel');
    let lang = queryParams.get('Lang');
    let hotelResID = queryParams.get('customerID');
    if (qPar !== null && qPar !== undefined) {
      let qLang = qPar.split('-')[2];
      let newQPar = qPar.split('-')[0] + '-' + qPar.split('-')[1];
      this.api
        .getWithUrl3(
          'https://app.hhoms.com/api/panel/WebMobilPage/' + newQPar,
          null,
          {}
        )
        .subscribe((resp) => {
          this.routingPageName = resp.pages.page;
          this.routingPageID = resp.pages.pageId;
          this._storage.get(Config.Keys.UrlHotel).then((urlHotel) => {
            if (urlHotel === null) {
              this.continueConstructerLoadingFunc(
                resp.pages.token,
                qLang,
                hotelResID
              );
            } else {
              if (resp.pages.token === urlHotel) {
                this.continueConstructerLoadingFunc(
                  resp.pages.token,
                  qLang,
                  hotelResID
                );
              } else {
                if (resp.pages.token !== null && urlHotel !== null) {
                  this._storage.removeAll().then(() => {
                    localStorage.clear();
                    this.continueConstructerLoadingFunc(
                      resp.pages.token,
                      qLang,
                      hotelResID
                    );
                  });
                } else {
                  this.continueConstructerLoadingFunc(
                    urlHotel !== null ? urlHotel : resp.pages.token,
                    qLang,
                    hotelResID
                  );
                }
              }
            }
          });
        });
    } else {
      this.routingPageName = queryParams.get('Page');
      this.routingPageID = queryParams.get('id');
      this._storage.get(Config.Keys.UrlHotel).then((urlHotel) => {
        if (urlHotel === null) {
          this.continueConstructerLoadingFunc(hotel, lang, hotelResID);
        } else {
          if (hotel === urlHotel) {
            this.continueConstructerLoadingFunc(hotel, lang, hotelResID);
          } else {
            if (hotel !== null && urlHotel !== null) {
              this._storage.removeAll().then(() => {
                localStorage.clear();
                this.continueConstructerLoadingFunc(hotel, lang, hotelResID);
              });
            } else {
              this.continueConstructerLoadingFunc(
                urlHotel !== null ? urlHotel : hotel,
                lang,
                hotelResID
              );
            }
          }
        }
      });
    }
  }

  closeDownloadOurApp() {
    // this.downloadButtonVisible = false;
    var elem = document.getElementById('DownloadOurAppButton');
    if (elem !== undefined && elem !== null) {
      elem.classList.add('hideDownloadOurAppButton');
    }
  }
  continueConstructerLoadingFunc(hotel, lang, hotelResID) {
    this._storage.set(Config.Keys.UrlHotel, hotel).then(() => {
      if (hotel !== null) {
        Config.Api.OwnerGroupID =
          hotel.split('-').length > 1 ? hotel : Hotels[hotel].ownerGroupId;
      } else {
        Config.Api.OwnerGroupID = Hotels['HHOMS'].ownerGroupId;
      }

      if (lang) {
        this._storage.set(Config.Keys.languageCode, lang).then(() => {
          this._events.publish(Config.Keys.languageCode_refresh, lang);
        });
      } else {
        this._storage.set(Config.Keys.languageCode, 'en-US').then(() => {
          this._events.publish(Config.Keys.languageCode_refresh, 'en-US');
        });
      }

      this.notify.infiniteShow().then(() => {
        setTimeout(() => {
          if (
            Config.Api.OwnerGroupID !== null &&
            Config.Api.OwnerGroupID !== undefined &&
            Config.Api.OwnerGroupID.trim() !== ''
          ) {
            this.notify.infiniteClose();
            this.page = this.ggPage;
            this._events.subscribe(Config.Keys.ActiveHotel_Refresh, () => {
              this.appStartConfiguration();
            });

            this.downloadOurAppButton = true;
            if (hotelResID !== null && hotelResID !== undefined) {
              this.api
                .post(Config.Api.Account_Login, {
                  RoomNo: '0',
                  BirthDate: 0,
                  HotelReservationId: parseInt(hotelResID),
                })
                .subscribe(
                  (loginResponse: any) => {
                    // if (!loginResponse.mobile) {
                    //   this.downloadOurAppButton = true;
                    // } else {
                    //   this.downloadOurAppButton = false;
                    // }

                    this._storage
                      .set(Config.Keys.userData, loginResponse)
                      .then(() => {
                        this._events.publish(
                          Config.Keys.userData_refresh,
                          loginResponse
                        );
                      });
                  },
                  (err) => {
                    console.log(err);
                    // this.downloadOurAppButton = true;
                  }
                );
            } else {
              // this._storage.get(Config.Keys.userData).then((userDataForLoading)=>{
              //   if(userDataForLoading !== null && userDataForLoading !== undefined){
              //     if(!userDataForLoading.mobile){
              //       this.downloadOurAppButton = true;
              //     } else {
              //       this.downloadOurAppButton = false;
              //     }
              //   } else {
              //     this.downloadOurAppButton = true;
              //   }
              // })
            }

            this.appStartConfiguration();

            this._storage.get(Config.Keys.ActiveHotel).then((data) => {});
            // this._storage.get(Config.Keys.isInfo).then((isInfo) => {
            //   this.isInfo = isInfo !== null ? isInfo : false;

            //   if (isInfo) {
            //     this.infoItem = this.ggPage.getDataForParentInfo();
            //     this.checkedVisibleStatus = this.checkInfoButtonVisible(
            //       this.ggPage.getDataForParentInfo()
            //     );
            //   }
            // });
            // this._events.subscribe(Config.Keys.isInfo, (isInfo) => {
            //   this.isInfo = isInfo;
            //   if (isInfo) {
            //     this.infoItem = this.ggPage.getDataForParentInfo();
            //     this.checkedVisibleStatus = this.checkInfoButtonVisible(
            //       this.ggPage.getDataForParentInfo()
            //     );
            //     this.checkURLForInfo();
            //   }
            // });

            this._events.subscribe('explore-clicked', () => {
              this.checkURLForTab();
            });

            this.router.events.subscribe((event: Event) => {
              if (event instanceof NavigationStart) {
                setTimeout(() => {
                  this.checkURLForInfo();
                  this.checkURLForTab();
                }, 100);
              }

              if (event instanceof NavigationEnd) {
                if (event.url == '/home') {
                  this.urlCheckingForliveSupport();
                  setTimeout(() => {
                    if (
                      this.ggPage.userData !== null &&
                      this.ggPage.userData !== undefined
                    ) {
                      this.notify.show().then(() => {
                        this.api
                          .get(
                            `${Config.Api.MobexSurveys}/day/${this.ggPage.userData.hotelReservationId}`
                          )
                          .subscribe(
                            (response) => {
                              this._survey.ShowAlertFunc(response);
                            },
                            (error) => {
                              //Günü anketi yoksa popup aç
                              if (!this.openedRandomCamp) {
                                this.toggleCampaignModal();
                                this.openedRandomCamp = true;
                              }
                              this.notify.hide();
                            }
                          );
                      });
                    } else {
                      //Misafir Login değilse popup aç
                      if (!this.openedRandomCamp) {
                        this.toggleCampaignModal();
                        this.openedRandomCamp = true;
                      }
                    }
                  }, 1000);
                } else {
                  this.liveSupportVisible = false;
                  this.whatsappNumber = null;
                }
                setTimeout(() => {
                  this.checkURLForInfo();
                  this.checkURLForTab();
                }, 100);
              }
            });

            this._events.subscribe('menu-clicked', (nonSenseData) => {
              this.menuOpened = !this.menuOpened;
            });
          }
        }, 3000);
      });
    });
  }

  urlCheckingForliveSupport() {
    this._storage.get(Config.Keys.globalSettings).then((globalSettings) => {
      this.whatsappNumber = globalSettings.whatsappNumber;
      if (globalSettings.liveSupport) {
        this.liveSupportVisible = true;
      } else {
        this.liveSupportVisible = false;
      }
    });
  }

  liveSupportStyleWidth() {
    if (
      this.MenuList.settingMenu.length > 0 &&
      !this.menuOpened &&
      this.tabVisible
    ) {
      this.liveSupportStyle = '90px !important';
    } else {
      this.liveSupportStyle = '10px !important';
    }
  }

  openMobexFreeServicesDetail(liveSupportParametrId) {
    this.api
      .get(`${Config.Api.MobexFreeServicesDetail}/${liveSupportParametrId}`)
      .subscribe((resp) => {
        this.page.setData(Config.Keys.item, resp.freeServices);
        this.page.setData(
          Config.Keys.pageLanguageContent,
          resp.languageContent
        );
        this.page.setData(
          Config.Keys.pageTitle,
          resp.freeServices.languageContent[this.page.shortLangCode].title
        );
        this.page.goWithoutTitle('/mobex-free-services-detail');
      });
  }

  openYCP() {
    this._storage.get(Config.Keys.globalSettings).then((globalSettings) => {
      this.api
        .get(Config.Api.StaticPageMobileLanguages + '/YCP')
        .subscribe((langMobil) => {
          this.page.setData(
            Config.Keys.pageLanguageContent,
            langMobil.languageContent
          );
          this.page.goWithoutTitle('/ycp');
        });
    });
  }

  openLiveSupport() {
    this._storage.get(Config.Keys.globalSettings).then((globalSettings) => {
      if (globalSettings.liveSupportParametrId !== -1) {
        this.openMobexFreeServicesDetail(globalSettings.liveSupportParametrId);
      } else {
        this.openYCP();
      }
    });
  }

  openWhatsApp() {
    this.ggPage.openUrl(
      'https://api.whatsapp.com/send?phone=' + this.whatsappNumber
    );
  }

  toggleCampaignModal() {
    this._storage.get(Config.Keys.globalSettings).then((globalSettings) => {
      if (globalSettings.campaingPopup) {
        // if (true) {
        this._campaign
          .GetCampaignPopupList(globalSettings.campaingPopupParametrId)
          .subscribe((campaignList: any) => {
            if (campaignList.campaigns.length > 0) {
              let randomized = Math.floor(
                Math.random() * campaignList.campaigns.length
              );
              this._storage
                .get(Config.Keys.randomizedCampaigns)
                .then((selectedCampList) => {
                  if (
                    selectedCampList == null &&
                    selectedCampList == undefined
                  ) {
                    let temp = [];
                    temp.push(campaignList.campaigns[0]); //campaignList.campaigns[randomized]
                    this._storage.set(Config.Keys.randomizedCampaigns, temp);
                    this.openRandomCamp(
                      campaignList.campaigns[0],
                      campaignList.languageContent
                    ); //campaignList.campaigns[randomized]
                  } else {
                    let beforeCamps: Array<any> = selectedCampList;
                    let storePush: boolean = true;
                    for (let i = 0; i < beforeCamps.length; i++) {
                      if (
                        beforeCamps.length + 1 >
                          campaignList.campaigns.length ||
                        campaignList.campaigns[beforeCamps.length].id ==
                          beforeCamps[i].id
                      ) {
                        //campaignList.campaigns[randomized]
                        storePush = false;
                      }
                    }
                    if (storePush) {
                      this.openRandomCamp(
                        campaignList.campaigns[beforeCamps.length],
                        campaignList.languageContent
                      ); //campaignList.campaigns[randomized]
                      beforeCamps[beforeCamps.length] =
                        campaignList.campaigns[beforeCamps.length]; //campaignList.campaigns[randomized]
                      this._storage.set(
                        Config.Keys.randomizedCampaigns,
                        beforeCamps
                      );
                    }
                  }
                });
            }
          });
      }
    });
  }

  async openRandomCamp(data, langContnet) {
    if (data.popupType == 1) {
      this.api
        .post(Config.Api.MobileStatistics, {
          customerId:
            this.ggPage.userData !== null
              ? this.ggPage.userData.mobexCustomersId
              : 0,
          deviceId: this._device.uuid !== null ? this._device.uuid : 'web',
          tableId: data.id,
          tableNo: 6,
          statu: true,
        })
        .subscribe();
    } else if (data.popupType == 2) {
      this.api
        .post(Config.Api.MobileStatistics, {
          customerId:
            this.ggPage.userData !== null
              ? this.ggPage.userData.mobexCustomersId
              : 0,
          deviceId: this._device.uuid !== null ? this._device.uuid : 'web',
          tableId: data.id,
          tableNo: 7,
          statu: true,
        })
        .subscribe();
    }

    const modal = await this._modalCtrl.create({
      component: RandomCampaignPage,
      cssClass: 'special-random-campaign-modal',
      componentProps: { data: data, langContent: langContnet },
    });
    return await modal.present();
  }

  toggleMenu() {
    this.menuOpened = false;
  }

  openParentInfo() {
    var lang = this.ggPage.shortLangCode;
    let tempData = {
      imageUrl: this.infoItem.imageUrl,
      title: this.infoItem.languageContent[lang].title,
      description:
        this.infoItem.languageContent[lang].titleInfo !== null &&
        this.infoItem.languageContent[lang].titleInfo !== undefined
          ? this.infoItem.languageContent[lang].titleInfo
          : this.infoItem.languageContent[lang].description,
    };

    this.page.setData(
      Config.Keys.pageTitle,
      this.infoItem.languageContent[lang].title
    );
    this.page.setDataForParentInfo(tempData);

    this._openPage.go('parent-detail');
  }

  checkURLForInfo() {
    this.checkedVisibleStatus = this.checkInfoButtonVisible(
      this.ggPage.getDataForParentInfo()
    );
    var url = this.router.url.split('/')[1];

    if (this.withoutInfoPages.indexOf(url) == -1) {
      this.urlCheckinfForParentInfo = true;
    } else {
      this.urlCheckinfForParentInfo = false;
    }
  }

  checkURLForTab() {
    setTimeout(() => {
      var url = this.router.url.split('/')[1];
      if (this.withoutTabPages.indexOf(url) == -1) {
        this._storage.get(Config.Keys.globalSettings).then((globalSettings) => {
          var splitted = url.split('#')[0];
          if (this.withoutTabPages.indexOf(splitted) == -1) {
            if (
              globalSettings != null &&
              (globalSettings.homePage == 'Home3' ||
                globalSettings.homePage == 'HomeBarut2' ||
                globalSettings.homePage == 'CullinanHome')
            ) {
              this.tabVisible = true;
            } else {
              this.tabVisible = false;
            }
          } else {
            this.tabVisible = false;
          }
        });
      } else {
        this.tabVisible = false;
      }
      Config.Api.homepageToolbar = this.tabVisible;
    }, 300);
  }

  checkInfoButtonVisible(infoObject) {
    return (
      infoObject !== undefined &&
      infoObject !== null &&
      ((infoObject.title !== undefined &&
        infoObject.title !== null &&
        infoObject.title.trim() !== '') ||
        (infoObject.languageContent[this.page.shortLangCode].titleInfo !==
          null &&
          infoObject.languageContent[this.page.shortLangCode].titleInfo !==
            undefined) ||
        (infoObject.languageContent[this.page.shortLangCode].description !==
          null &&
          infoObject.languageContent[this.page.shortLangCode].description !==
            undefined))
    );
  }

  appStartConfiguration() {
    this._storage.remove(Config.Keys.globalSettings).then(() => {});
    this._storage.remove(Config.Keys.mobileSettings).then(() => {});
    this._storage.remove('AnketHomeData').then(() => {});
    this._storage.remove('menu').then(() => {});

    this._storage
      .get(Config.Keys.ActiveHotelSelected)
      .then((selectedHotelStatus) => {
        if (selectedHotelStatus == null && !selectedHotelStatus) {
          if (Config.Api.HotelOpenWithQR) {
            this.router.navigate(['/open-qr']);
          } else {
            if (Config.Api.IsPersonel) {
              this.router.navigate(['/group-login']);
            } else {
              this.router.navigate(['/group-hotels']);
            }
          }
        } else {
          this._events.subscribe(
            Config.Keys.languageCode_refresh,
            (langCodeData) => {
              let langCode = langCodeData;
              this.uniLang = langCode;
              this.shortUniLang = langCode.split('-')[0];
              this.refreshLanguageStatus = true;
              this.reSummonInitializeApp = true;
            }
          );

          this._storage.get(Config.Keys.languageCode).then((langCode) => {
            if (langCode !== null && langCode !== undefined) {
              this.uniLang = langCode;
              this.shortUniLang = langCode.split('-')[0];
            }
          });

          if (Config.Api.AssaAbloy) {
            this._events.subscribe(Config.Keys.userData_refresh, (userData) => {
              this.userData = userData;
              if (userData !== null && userData !== undefined) {
                this.unRegisterUserForAssa(true, userData);
              } else {
                this.unRegisterUserForAssa(false, null);
              }
            });
          }

          this._events.subscribe('modal:added', (modalData) => {
            this.globalModal.push(modalData);
          });

          this._events.subscribe('modal:closed', async () => {
            this.globalModal.pop();
          });

          this._events.subscribe(
            'unread-notification-count::refresh',
            (resp) => {
              this.unReadNotificationCount = resp;
            }
          );

          this.initializeApp();
        }
      });
  }

  globalLanguage() {
    this._storage.get('isThatFirstTimeLoadApp').then((isFirstLoad) => {
      this._storage.get('isThatFirstTimeLoadApp').then((isFirstLoad) => {
        if (isFirstLoad || isFirstLoad == null) {
          this._storage.set('isThatFirstTimeLoadApp', false);
          this.uniLang = 'tr-TR';
          let isMobileLanguage = false;

          this._storage
            .get(Config.Keys.mobileLanguages)
            .then((mobileLanguages) => {
              for (let index = 0; index < mobileLanguages.length; index++) {
                if (
                  mobileLanguages[index].value !== null &&
                  mobileLanguages[index].value !== undefined
                ) {
                  if (
                    mobileLanguages[index].value.split('-')[0] ===
                    this.uniLang.split('-')[0]
                  ) {
                    isMobileLanguage = true;
                    this._storage.set(Config.Keys.languageCode, this.uniLang);
                    this.shortUniLang = this.uniLang.split('-')[0];
                    this._storage
                      .set(Config.Keys.languageCode, this.uniLang)
                      .then(() => {
                        this.initPushNotification();
                      });
                    break;
                  }
                }
              }

              if (!isMobileLanguage) {
                this.uniLang = mobileLanguages[0].value;
                this._storage.set(Config.Keys.languageCode, this.uniLang);
                this.shortUniLang = this.uniLang.split('-')[0];
                this._storage
                  .set(Config.Keys.languageCode, this.uniLang)
                  .then(() => {
                    this.initPushNotification();
                  });
              }
            });
        } else {
          this.initPushNotification();
        }
      });
    });
  }

  checkFormatFont(url: string) {
    let urlEnd = url.split('.')[url.split('.').length - 1];

    if (urlEnd === 'woff2') {
      return 'woff2';
    } else if (urlEnd === 'woff') {
      return 'woff';
    } else if (urlEnd === 'otf') {
      return 'opentype';
    } else {
      return 'truetype';
    }
  }

  downloadOurApp() {
    var elem = document.getElementById('hiddenSelectForDownload');
    if (elem !== null && elem !== undefined) {
      elem.click();
    }
  }

  selectedValueForDownload(event: any) {
    console.log(event.target.value);
    let selectedVal = event.target.value;
    this.selectedValueForDownloadButton = null;
    event.target.value = null;

    this._storage.get(Config.Keys.globalSettings).then((globalSettings) => {
      if (selectedVal === 'ios') {
        window.open(
          'https://apps.apple.com/us/app/hhoms/id' + globalSettings.iosMarket,
          '_blank'
        );
      }

      if (selectedVal === 'android') {
        window.open(
          'http://market.android.com/details?id=' +
            globalSettings.androidMarket,
          '_blank'
        );
      }
    });
  }

  initializeApp() {
    this._platform.ready().then(() => {
      console.log(this._platform.platforms());
      this.isDesktop = this._platform.is('desktop');
      // this.router.navigate(['/load']);
      this.notify.show().then(() => {
        this._globalSettings.getGlobalSettings().subscribe(
          (globalResponse) => {
            this.globLangContent = globalResponse.languageContent;
            this.globSettings = globalResponse.globalSettings;

            if (
              globalResponse.globalSettings.appIcon !== null &&
              globalResponse.globalSettings.appIcon !== undefined
            ) {
              this.downloadButtonVisible = true;
            } else {
              this.downloadButtonVisible = false;
            }

            this._storage
              .set(Config.Keys.fontSettings, globalResponse.fontSetting)
              .then(() => {
                this._storage
                  .get(Config.Keys.fontSettings)
                  .then((fontSettings) => {
                    this.titleFont = fontSettings.title;
                  })
                  .then(() => {
                    this._storage.set(
                      Config.Keys.mobileSettings,
                      globalResponse
                    );
                    this._events.publish(
                      Config.Keys.mobileSettings,
                      globalResponse
                    );
                    this._storage.set(
                      Config.Keys.mobileLanguages,
                      globalResponse.mobileLanguages
                    );
                    this._events.publish(
                      Config.Keys.mobileLanguages,
                      globalResponse.mobileLanguages
                    );
                    let globalSettings = globalResponse.globalSettings;
                    this.isLeftMenuActive = globalSettings.isLeftMenu;
                    this.toolbarColor = globalSettings.toolbarColor;
                    this._storage.set(
                      Config.Keys.ownerID,
                      globalResponse.ownerId
                    );
                    this._storage
                      .set(Config.Keys.globalSettings, globalSettings)
                      .then(() => {
                        Config.homepageSelector = globalSettings.homePage;
                        if (
                          Config.homepageSelector == 'Home3' ||
                          Config.homepageSelector == 'HomeBarut2' ||
                          Config.homepageSelector == 'CullinanHome'
                        ) {
                          Config.Api.homepageToolbar = true;
                          this.tabVisible = true;
                        }
                        this._storage
                          .set(Config.Keys.globalAlingment, {
                            pageTitleAling: globalSettings.pageTitleAling,
                            exploreButton: globalSettings.exploreButon,
                            butonDetailText: globalSettings.butonDetailText,
                          })
                          .then(() => {
                            this._storage
                              .set(
                                Config.Keys.SednaSettings,
                                globalResponse.sednaSetting
                              )
                              .then(() => {
                                this.globalWhiteLogo = globalSettings.whiteLogo;
                                this.leftBackground = globalSettings.leftLogo;
                                this._events.publish(
                                  Config.Keys.globalSettings,
                                  globalSettings
                                );
                                this._events.publish(
                                  Config.Keys.globalAlingment,
                                  {
                                    pageTitleAling:
                                      globalSettings.pageTitleAling,
                                    exploreButton: globalSettings.exploreButon,
                                    butonDetailText:
                                      globalSettings.butonDetailText,
                                  }
                                );
                                this._storage.set('registerToken', 'emre');
                                this._storage
                                  .get(Config.Keys.languageCode)
                                  .then((langCode) => {
                                    this.uniLang =
                                      langCode !== null &&
                                      langCode !== undefined
                                        ? langCode
                                        : Config.Defaults.LongLanguageCode;
                                    this.shortUniLang =
                                      langCode !== null &&
                                      langCode !== undefined
                                        ? langCode.split('-')[0]
                                        : Config.Defaults.ShortLanguageCode;
                                    if (
                                      langCode == null ||
                                      langCode == undefined
                                    ) {
                                      this._storage.set(
                                        Config.Keys.languageCode,
                                        Config.Defaults.LongLanguageCode
                                      );
                                    }
                                    this.initLanguage(); // dil ayarlamasini yap
                                  });
                              });
                          });
                      });
                  });
              });
          },
          (error) => {
            this.api
              .get(
                Config.Api.StaticPageMobileLanguages +
                  '/' +
                  Config.PageNames.MobileLanguages
              )
              .subscribe((langMobil) => {
                this.mobileLang = langMobil.languageContent;
                this.uniLang = 'tr-TR';
                let isMobileLanguage = false;

                for (let index = 0; index < this.mobileLang.length; index++) {
                  if (
                    this.mobileLang[index].value !== null &&
                    this.mobileLang[index].value !== undefined
                  ) {
                    if (
                      this.mobileLang[index].value.split('-')[0] ===
                      this.uniLang.split('-')[0]
                    ) {
                      isMobileLanguage = true;
                      this.shortUniLang = this.uniLang.split('-')[0];
                      break;
                    }
                  }
                }
                if (!isMobileLanguage) {
                  this.uniLang = this.mobileLang[0].value;
                  this.shortUniLang = this.uniLang.split('-')[0];
                }
              });
          }
        );
      });
    });
  }

  ngOnInit() {
    return new Promise((resolve, reject) => {
      this._storage.set(Config.Keys.shoppingCart, undefined);
      this._events.publish(Config.Keys.shoppingCart_refresh, []);
      resolve(undefined);
    });
  }

  initLanguage() {
    this.ggPage.pageInit();
    this.getMenuList();
  }

  async unRegisterUserForAssa(loginSuccess: boolean = false, userData: any) {
    this._platform.ready().then(() => {
      this.notify.show().then(async () => {
        const { value } = await MobileKeys.unregisterUser();
        this.notify.hide();

        if (loginSuccess) {
          this.registerUserForAssa(userData);
        }
      });
    });
  }

  async registerUserForAssa(userData) {
    this._platform.ready().then(() => {
      this.notify.show().then(() => {
        setTimeout(async () => {
          const { value } = await MobileKeys.registerUser({
            value: userData.hotelReservationId + '',
          });
          if (value == 'already') {
            // this.unRegisterUser(userData);
            this._storage
              .get(Config.Keys.AssaRegisterEndpoint)
              .then((assaEndpoint) => {});
          } else {
            this._storage
              .set(Config.Keys.AssaRegisterEndpoint, value)
              .then(() => {});
          }

          this.notify.hide();
        }, 5000);
      });
    });
  }

  pushUserStatus() {
    this._storage.get(Config.Keys.ActiveHotel).then((ACTIVE_HOTEL) => {
      if (ACTIVE_HOTEL.startUp) {
        this._storage.get(Config.Keys.userData).then((userData) => {
          this.initLanguage();
        });
      } else {
        this.router.navigate(['/home']);
        this.initLanguage();
      }
    });
  }

  closeMenu(func: any) {
    this._menu.close('custom-menu-checker').then(() => {
      func();
    });
  }

  getMenuList() {
    this._getMenu.GetMenuList().subscribe(
      (resp) => {
        this._storage.set('menu', resp).then(() => {
          this.MenuList = resp;
          this.notify.hide();
          this.closeNotify();
          this.router.navigate(['/home']);
          if (
            this.routingPageName !== null &&
            this.routingPageID !== null &&
            this.routingPageID !== undefined &&
            this.routingPageName !== undefined
          ) {
            // AnnouncementDetail: "AnnouncementDetail"
            if (this.routingPageName === Config.PageNames.AnnouncementDetail) {
              this.api
                .get(`${Config.Api.MobexNotices_detail}/${this.routingPageID}`)
                .subscribe(
                  (resp) => {
                    this.ggPage.setData(
                      Config.Keys.pageTitle,
                      resp.languageContent[this.ggPage.shortLangCode].title
                    );
                    let ddata = resp;
                    this.ggPage.setData('item', ddata);
                    this.navigateNotificationPages('announcement-detail');
                  },
                  (err) => {
                    this.notify.confirm2(
                      '',
                      err.message || 'Server Error',
                      'Tamam',
                      () => {}
                    );
                  }
                );
            } else if (
              this.routingPageName === Config.PageNames.CampaignDetail
            ) {
              this.api
                .get(
                  `${Config.Api.MobexCampaigns_detail}/${this.routingPageID}`
                )
                .subscribe(
                  (resp) => {
                    this.ggPage.setData(
                      Config.Keys.pageTitle,
                      resp.campaign.languageContent[this.ggPage.shortLangCode]
                        .title
                    );
                    let ddata = resp;
                    this.ggPage.setData('item', ddata);
                    this.navigateNotificationPages('campaign-detail');
                  },
                  (err) => {
                    this.notify.confirm2(
                      '',
                      err.message || 'Server Error',
                      'Tamam',
                      () => {}
                    );
                  }
                );
            } else if (
              this.routingPageName === Config.PageNames.FreeServiceDetail
            ) {
              setTimeout(() => {
                this.openMobexFreeServicesDetail(this.routingPageID);
              }, 500);
            } else if (
              this.routingPageName === Config.PageNames.MobexActivity
            ) {
              this.ggPage.setData('MobexActivity', {
                isSettings: {
                  isCalender: false,
                },
              });

              this._activity
                .GetActivity(
                  parseInt(this.routingPageID),
                  moment().format('DD.MM.YYYY')
                )
                .subscribe((re: any) => {
                  console.log(re);
                  this.ggPage.setData('item', re);
                  this.ggPage.setData(
                    Config.Keys.pageTitle,
                    // re.languageContent[this.ggPage.shortLangCode].title
                    ''
                  );
                  setTimeout(() => {
                    this.router.navigate([
                      `activity/${this.routingPageID}/${moment().format(
                        'DD.MM.YYYY'
                      )}`,
                    ]);
                  }, 2000);
                });
            } else {
              setTimeout(() => {
                this._openPage.OpenPage({
                  parametrId: parseInt(this.routingPageID),
                  id: parseInt(this.routingPageID),
                  mobilePage: this.routingPageName,
                  languageContent: {
                    tr: '',
                    en: '',
                    ru: '',
                    de: '',
                  },
                });
              }, 2000);
            }
          }
          this.checkURLForTab();
        });
      },
      (err) => {
        this.notify.hide();
      }
    );
  }

  OpenPage(item) {
    this.menuOpened = false;
    this._openPage.OpenPage(item);
  }

  initPushNotification() {
    this.pushUserStatus();
  }

  navigateNotificationPages(pageName) {
    /*     if (this.doneInitalizing) {
          this.router.navigate([pageName])
        } else {
          setTimeout(() => {
            this.navigateNotificationPages(pageName);
          }, 200)
        } */
    setTimeout(() => {
      this.router.navigate([pageName]);
    }, 2500);
  }

  openSocialMedia(item) {
    let webPageUrl = item.languageContent[this.shortUniLang].webPageUrl;

    if (webPageUrl !== null) this.ggPage.openUrl(webPageUrl);
    else this.OpenPage(item);
  }

  openGonca() {
    this.ggPage.openUrl('https://hhoms.com/');
  }

  closeNotify() {
    this.notify.hide();
  }
}
